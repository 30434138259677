export const getIcon = (icon: string) => {
  switch (icon) {
    case "plus":
      return <i className="fa-solid fa-plus"></i>;
    case "trash":
      return <i className="fa-regular fa-trash-can"></i>;
    case "upload":
      return <i className="fa-regular fa-cloud-arrow-up"></i>;
    case "media":
      return <i className="fa-solid fa-photo-film-music"></i>;
    case "close":
      return <i className="fa-solid fa-circle-xmark"></i>;
    case "card":
      return <i className="fa-light fa-credit-card"></i>;
    case "details":
      return <i className="fa-light fa-ballot-check"></i>;
    case "select":
      return <i className="fa-light fa-hand-pointer"></i>;
    case "user":
      return <i className="fa-solid fa-user"></i>;
    case "users":
      return <i className="fa-solid fa-users"></i>;
    case "error":
      return <i className="fa-regular fa-hexagon-exclamation"></i>;
    case "success":
      return <i className="fa-solid fa-octagon-check"></i>;
    case "loader":
      return <i className="fas fa-circle-notch fa-spin"></i>;
    case "workspace":
      return <i className="fa-solid fa-building"></i>;
    case "selected":
      return <i className="fa-solid fa-check"></i>;
    case "verified":
      return <i className="fa-regular fa-badge-check"></i>;
    case "choose":
      return <i className="fa-regular fa-circle-dot"></i>;
    case "unselected-box":
      return <i className="fa-regular fa-circle"></i>;
    case "selected-box":
      return <i className="fa-regular fa-circle-dot"></i>;
    case "information":
      return <i className="fa-regular fa-circle-info"></i>;
    case "mail":
      return <i className="fa-regular fa-envelope"></i>;
    case "idea":
      return <i className="fa-regular fa-lightbulb"></i>;
    case "menu":
      return <i className="fa-regular fa-bars"></i>;
    case "help":
      return <i className="fa-solid fa-seal-question"></i>;
    case "record":
      return <i className="fa-regular fa-microphone"></i>;
    case "stop":
      return <i className="fa-solid fa-stop"></i>;
    case "bug":
      return <i className="fa-regular fa-flag"></i>;
    case "rocket":
      return <i className="fa-light fa-rocket-launch"></i>;
    case "link":
      return <i className="fa-solid fa-link"></i>;
    case "spotify":
      return <i className="fa-brands fa-spotify"></i>;
    case "save":
      return <i className="fa-regular fa-floppy-disk"></i>;
    case "refresh":
      return <i className="fa-sharp fa-solid fa-arrows-rotate"></i>;
    case "clipboard":
      return <i className="fa-regular fa-clipboard"></i>;
    case "dialog":
      return <i className="fa-regular fa-comments-question-check"></i>;
    case "back":
      return <i className="fa-regular fa-arrow-left"></i>;
    case "picture":
      return <i className="fa-regular fa-image"></i>;
    case "blogpost":
      return <i className="fa-regular fa-file-lines"></i>;
    case "movie":
      return <i className="fa-solid fa-film"></i>;
    case "transcription":
      return <i className="fa-regular fa-file-lines"></i>;
    case "female-voice":
      return <i className="fa-regular fa-person-dress"></i>;
    case "male-voice":
      return <i className="fa-solid fa-person"></i>;
    case "play":
      return <i className="fa-regular fa-circle-play"></i>;
    case "pause":
      return <i className="fa-regular fa-circle-pause"></i>;
    case "forward":
      return <i className="fa-solid fa-forward"></i>;
    case "backward":
      return <i className="fa-solid fa-backward"></i>;
    case "forward-step":
      return <i className="fa-solid fa-forward-step"></i>;
    case "backward-step":
      return <i className="fa-solid fa-backward-step"></i>;
    case "previous-line":
      return <i className="fa-solid fa-arrow-turn-left"></i>;
    case "next-line":
      return <i className="fa-solid fa-arrow-turn-right"></i>;
    case "volume-on":
      return <i className="fa-solid fa-volume-high"></i>;
    case "volume-off":
      return <i className="fa-solid fa-volume-slash"></i>;
    case "remove":
      return <i className="fa-regular fa-circle-xmark"></i>;
    case "arrow-right":
      return <i className="fa-regular fa-arrow-right-long"></i>;
    case "expand":
      return <i className="fa-solid fa-expand"></i>;
    case "minimise":
      return <i className="fa-solid fa-compress"></i>;
    case "download":
      return <i className="fa-solid fa-down-to-line"></i>;
    case "cloud":
      return <i className="fa-solid fa-cloud-arrow-up"></i>;
    case "spinner":
      return <i className="fas fa-circle-notch fa-spin"></i>;
    case "calendar":
      return <i className="fa-regular fa-calendar"></i>;
    case "available":
      return <i className="fa-duotone fa-hexagon-check"></i>;
    case "unavailable":
      return <i className="fa-solid fa-hexagon-xmark"></i>;
    case "start-beginning":
      return <i className="fa-solid fa-backward-fast"></i>;
    case "exit":
      return <i className="fa-solid fa-xmark"></i>;
    case "support":
      return <i className="fa-sharp fa-regular fa-circle-info"></i>;
    case "align-horizontal-left":
      return <i className="fa-solid fa-align-left"></i>;
    case "align-horizontal-center":
      return <i className="fa-solid fa-align-center"></i>;
    case "align-horizontal-right":
      return <i className="fa-solid fa-align-right"></i>;
    case "align-vertical-bottom":
      return <i className="fa-solid fa-objects-align-bottom"></i>;
    case "align-vertical-center":
      return <i className="fa-solid fa-objects-align-center-horizontal"></i>;
    case "align-vertical-top":
      return <i className="fa-solid fa-objects-align-top"></i>;
    case "minus":
      return <i className="fa-solid fa-minus"></i>;
    case "1":
      return <i className="fa-solid fa-square-1"></i>;
    case "2":
      return <i className="fa-solid fa-square-2"></i>;
    case "wave":
      return <i className="fa-solid fa-hand-wave"></i>;
    case "editor":
      return <i className="fa-regular fa-clapperboard-play"></i>;
    case "done":
      return <i className="fa-solid fa-check"></i>;
    case "mobile":
      return <i className="fa-regular fa-mobile"></i>;
    case "tablet":
      return <i className="fa-regular fa-tablet"></i>;
    case "tv":
      return <i className="fa-regular fa-tv"></i>;
    case "search":
      return <i className="fa-regular fa-magnifying-glass"></i>;
    case "arrow-up":
      return <i className="fa-solid fa-angle-up"></i>;
    case "arrow-down":
      return <i className="fa-solid fa-angle-down"></i>;
    case "dash":
      return <i className="fa-regular fa-dash"></i>;
    case "underline":
      return <i className="fa-regular fa-underline"></i>;
    case "strikethrough":
      return <i className="fa-regular fa-strikethrough"></i>;
    case "regular-text":
      return <i className="fa-regular fa-text"></i>;
    case "italic-text":
      return <i className="fa-regular fa-italic"></i>;
    case "bold-text":
      return <i className="fa-solid fa-bold"></i>;
    case "not-visible":
      return <i className="fa-solid fa-eye-slash"></i>;
    case "grip":
      return <i className="fa-solid fa-grip-dots-vertical"></i>;
    case "revision":
      return <i className="fa-regular fa-square-list"></i>;
    case "revert":
      return <i className="fa-solid fa-rotate-reverse"></i>;
    case "caption-menu":
      return <i className="fa-solid fa-ellipsis-vertical"></i>;
    case "editor-back":
      return <i className="fa-solid fa-chevron-left"></i>;
    case "gear":
      return <i className="fa-regular fa-gear"></i>;
    case "word":
      return <i className="fa-regular fa-messages"></i>;
    default:
      return null;
  }
};
