import { Link } from "react-router-dom";
import Button from "../../../library/button";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import { getIcon } from "../../../utils/get-icon";
import ToggleButtons from "../../../library/toggle-buttons";
import {
  ButtonsWrapper,
  ContactLink,
  Container,
  OptionWrapper,
  DetailCol,
  DetailRow,
  OptionsWrapper,
  OptionCol,
  OptionsRow,
  ListWrapper,
  DetailIcon,
  PriceWrapper,
  ToggleWrapper,
} from "./styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FEATURE_LIST, SUBSCRIPTION_TIERS } from "./utils";

const Content = () => {
  const { t } = useTranslation();
  const [selectedSubscription, setSelectedSubscription] =
    useState<string>("monthly");

  const handleTabSelection = (selection: string) => {
    setSelectedSubscription(selection);
  };

  return (
    <Container>
      <ToggleWrapper>
        <ToggleButtons
          selected={selectedSubscription}
          options={[
            {
              title: t(
                "register.views.select-subscription.payment-duration.monthly-selection.title"
              ),
              value: "monthly",
            },
            {
              title: t(
                "register.views.select-subscription.payment-duration.annually-selection.title"
              ),
              value: "annually",
            },
          ]}
          onSelect={handleTabSelection}
        />
      </ToggleWrapper>
      <OptionsWrapper>
        <OptionsRow>
          {SUBSCRIPTION_TIERS.map((tier) => {
            const priceIndex = selectedSubscription === "monthly" ? 0 : 1;
            return (
              <OptionCol md={4}>
                <OptionWrapper
                  className={tier.title !== "Randi Small" ? "highlight" : ""}
                >
                  <Title>{tier.title}</Title>
                  <PriceWrapper>
                    <Title size="sm">
                      {tier.prices[priceIndex]}
                      {tier.title !== "Randi Small" && "kr"}
                    </Title>
                  </PriceWrapper>
                  <ListWrapper>
                    {FEATURE_LIST.map((feature) => (
                      <DetailRow>
                        <DetailCol className="my-auto">
                          <Text color="dark">{feature.title}</Text>
                        </DetailCol>
                        <DetailCol className="icon my-auto">
                          {feature.availableIn.includes(tier.title) ? (
                            <DetailIcon className="check">
                              {getIcon("available")}
                            </DetailIcon>
                          ) : (
                            <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                          )}
                        </DetailCol>
                      </DetailRow>
                    ))}
                  </ListWrapper>
                  <ButtonsWrapper>
                    <Link to="/register">
                      <Button
                        variant="regular"
                        color={tier.title !== "Randi Small" ? "blue" : "grey"}
                      >
                        Kom i gang nå
                      </Button>
                    </Link>
                    <ContactLink to="mailto:andreas@randi.ai">
                      Kontakt oss for mer informasjon
                    </ContactLink>
                  </ButtonsWrapper>
                </OptionWrapper>
              </OptionCol>
            );
          })}
        </OptionsRow>
      </OptionsWrapper>
    </Container>
  );
};

export default Content;

/*
<OptionCol md={4}>
            <OptionWrapper>
              <Title>Randi Small</Title>
              <PriceWrapper>
                <Title size="sm">Gratis</Title>
              </PriceWrapper>
              <ListWrapper>
                {FEATURE_LIST.map((feature) => (
                  <DetailRow>
                    <DetailCol className="my-auto">
                      <Text color="dark">{feature.title}</Text>
                    </DetailCol>
                    <DetailCol className="icon my-auto">
                      {feature.availableIn.includes("small") ? (
                        <DetailIcon className="check">
                          {getIcon("available")}
                        </DetailIcon>
                      ) : (
                        <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                      )}
                    </DetailCol>
                  </DetailRow>
                ))}
              </ListWrapper>

              <ButtonsWrapper>
                <Link to="/register">
                  <Button variant="regular" color="grey">
                    Kom i gang nå
                  </Button>
                </Link>
                <ContactLink to="mailto:andreas@randi.ai">
                  Kontakt oss for mer informasjon
                </ContactLink>
              </ButtonsWrapper>
            </OptionWrapper>
          </OptionCol>
          <OptionCol md={4}>
            <OptionWrapper>
              <Title>Randi Social</Title>
              <PriceWrapper>
                <Title size="sm">
                  {selectedSubscription === "monthly"
                    ? "149kr per mnd"
                    : "3 999kr per år"}
                </Title>
              </PriceWrapper>
              <ListWrapper>
                {FEATURE_LIST.map((feature) => (
                  <DetailRow>
                    <DetailCol className="my-auto">
                      <Text color="dark">{feature.title}</Text>
                    </DetailCol>
                    <DetailCol className="icon my-auto">
                      {feature.availableIn.includes("social") ? (
                        <DetailIcon className="check">
                          {getIcon("available")}
                        </DetailIcon>
                      ) : (
                        <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                      )}
                    </DetailCol>
                  </DetailRow>
                ))}
              </ListWrapper>
              
            </OptionWrapper>
          </OptionCol>
          <OptionCol md={4}>
            <OptionWrapper className="version-go">
              <Title>Randi Go</Title>
              <PriceWrapper>
                <Title size="sm">
                  {selectedSubscription === "monthly"
                    ? "349kr per mnd"
                    : "3 999kr per år"}
                </Title>
              </PriceWrapper>
              <ListWrapper>
                {FEATURE_LIST.map((feature) => (
                  <DetailRow>
                    <DetailCol className="my-auto">
                      <Text color="dark">{feature.title}</Text>
                    </DetailCol>
                    <DetailCol className="icon my-auto">
                      {feature.availableIn.includes("go") ? (
                        <DetailIcon className="check">
                          {getIcon("available")}
                        </DetailIcon>
                      ) : (
                        <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                      )}
                    </DetailCol>
                  </DetailRow>
                ))}
              </ListWrapper>
              <ButtonsWrapper>
                <Link to="/register">
                  <Button variant="regular" color="blue">
                    Kom i gang nå
                  </Button>
                </Link>
                <ContactLink to="mailto:andreas@randi.ai">
                  Kontakt oss for mer informasjon
                </ContactLink>
              </ButtonsWrapper>
            </OptionWrapper>
          </OptionCol>
*/
