import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import {
  Article,
  Container,
  ListItem,
  OrderedList,
  Paragraph,
  Section,
} from "./styled";

const PrivacyPolicyContent = () => {
  return (
    <Section>
      <Container>
        <Article>
          <Paragraph>
            <Title>Brukervilkår (lisensavtale)</Title>
            <Text color="dark">
              Randi Labs AS (“oss”, “vi” eller “vår”) driver nettstedet
              www.randi.ai (“tjenesten”). Randi er et online
              videoredigeringsverktøy som tilbyr en rekke forskjellige
              undertjenester som tale til tekst, tekst til tale og en
              video-editor som gjør det mulig for brukere å enkelt bearbeide og
              jobbe med automatisk generert teksting og manuelle undertekster
              til videoene sine.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Aksept av avtale</Title>
            <Text color="dark">
              Når du aksepterer denne avtalen godtar du vilkårene i avtalen og
              det blir inngått en juridisk bindende avtale mellom deg (kunden)
              og Randi (Randi Labs AS). Denne avtalen regulerer din bruksrett av
              tjenesten, og ved å merke av "Jeg godtar brukervilkårene" under
              registrering, aksepterer du denne avtalen.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Krav om bruk</Title>
            <Text color="dark">
              Brukerne forplikter seg til å overholde all gjeldende norsk
              lovgivning ved bruk av denne digitale tjenesten. Dette innebærer
              at alt innhold som lastes opp, deles, eller på annen måte
              distribueres gjennom tjenesten, må være i full overensstemmelse
              med norske lover. Nedenfor finner du en oversikt over våre krav
              for bruk:
            </Text>
            <OrderedList>
              <ListItem>
                Du må være myndig (over 18 år) for å benytte deg av denne
                tjenesten.
              </ListItem>
              <ListItem>
                Du må være et menneske, da vi ikke tillater automatisert
                software, macro eller utvidelser på/av/for vår tjeneste.
              </ListItem>
              <ListItem>
                Du må oppgi dine ekte personopplysninger som navn, e-postadresse
                og lignende informasjon ved registrering.
              </ListItem>
              <ListItem>
                Din konto kan kun benyttes av èn person, og skal alltid kun
                tilhøre èn person. Ved mistanke om deling av kontoer vil
                brukeren bli stengt ned umiddelbart og du vil ikke ha krav på
                refundering.
              </ListItem>
              <ListItem>
                Det er ikke lov til å bruke tjenesten til å drive
                konsulentvirksomhet ved f.eks å selge transkriberingsjobber som
                teksting eller annet lignende arbeid generert av Randi
                (tjenesten) til en tredjepart, hverken som privatperson eller
                virksomhet, til en privatperson eller virksomhet, uten skriftlig
                samtykke fra oss (Randi). Brudd på dette vil resultere i
                suspensjon av konto.
              </ListItem>
              <ListItem>
                Du er selv ansvarlig for å vedlikeholde sikkerheten rundt din
                egen konto.
              </ListItem>
              <ListItem>
                Du er selv ansvarlig for alt innhold som blir publisert og
                aktiviteter som skjer i sammenheng med bruk av tjenesten.
              </ListItem>
              <ListItem>
                Det er strengt forbudt å laste opp, dele, eller distribuere
                materiale som er ulovlig. Dette inkluderer, men er ikke
                begrenset til, innhold som fremmer, illustrerer eller oppfordrer
                til vold, utpressing, hatkriminalitet, eller annen kriminell
                adferd.
              </ListItem>
              <ListItem>
                Det er et absolutt forbud mot å laste opp, dele, eller på noen
                måte distribuere barnepornografi eller materiale som
                seksualiserer mindreårige. Brudd på dette vil medføre
                umiddelbare tiltak, inkludert, men ikke begrenset til,
                suspensjon av konto, rapportering til relevante myndigheter og
                rettslig forfølgelse.
              </ListItem>
              <ListItem>
                Du skal ikke bruke denne tjenesten til kriminell aktivitet eller
                noe som bryter med våre retningslinjer eller Norges lover.
              </ListItem>
            </OrderedList>
          </Paragraph>
          <Paragraph>
            <Title>Dataeierskap</Title>
            <Text color="dark">
              Som kunde hos Randi eier du alltid dine egne data. Alt som lastes
              opp, bearbeides og lastes ned gjennom tjenesten forblir din
              private eiendom. Randi Labs AS tar ikke eierskap til innholdet
              ditt, og vi har ingen rettigheter til å bruke materialet ditt uten
              ditt uttrykkelige skriftlige samtykke.
              <br />
              <br />
              Randi benytter OpenAI Whisper API for automatisk transkripsjon av
              lyd og video. Vi har en databehandleravtale (DPA) med OpenAI
              Ireland, som sikrer at:
              <ul>
                <li>All behandling skjer i henhold til GDPR.</li>
                <li>
                  Ingen data brukes til AI-trening eller modellforbedring.
                </li>
                <li>
                  Ingen data deles med tredjeparter utenfor det som er nødvendig
                  for tjenestens funksjonalitet.
                </li>
                <li>
                  Lyd- og videodata behandles midlertidig og slettes automatisk
                  etter maks 30 dager.
                </li>
                <li>
                  Data som sendes til OpenAI Whisper er kryptert med TLS 1.2+
                  under overføring og AES-256 ved lagring.
                </li>
              </ul>
              I tillegg bruker vi Google Cloud Platform (EU) for sikker lagring
              og behandling av data, samt Stripe for betalingsbehandling. Ingen
              av dine data selges eller deles med tredjeparter til kommersielle
              formål.
              <br />
              <br />
              For mer informasjon om OpenAI sine retningslinjer for personvern
              og sikkerhet, se{" "}
              <a
                target="_blank"
                href="https://trust.openai.com"
                rel="noreferrer"
              >
                OpenAI Security Portal
              </a>
              .
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Personvern og datamodeller</Title>
            <Text color="dark">
              Ved å bruke Randi erkjenner og godtar du at tjenesten benytter
              OpenAI Whisper API for å konvertere tale til tekst. Under denne
              prosessen sendes lydopptakene til OpenAI sine servere, hvor
              Whisper-algoritmen utfører transkripsjonen. Vi har en
              databehandleravtale (DPA) med OpenAI Ireland, noe som sikrer at
              all behandling av personopplysninger skjer i samsvar med GDPR.
              <br />
              <br />
              OpenAI oppbevarer data som behandles gjennom Whisper API i opptil
              30 dager for feilsøkingsformål før det slettes automatisk. Disse
              dataene brukes ikke til AI-trening eller modellforbedring, og de
              deles ikke med noen tredjeparter. All data som overføres mellom
              Randi og OpenAI er kryptert med TLS 1.2+ under overføring og
              AES-256 ved lagring. OpenAI er SOC 2 Type II-kompatibel,
              GDPR-samsvarende og har sikkerhetssertifiseringer som CCPA og CSA
              STAR. <br />
              <br />
              Opplastet materiale og transkript lagres trygt på våre servere
              innenfor EU og er kryptert i samsvar med sikkerhetsstandardene til
              Google Cloud Platform.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Tilgang til tjenesten</Title>
            <Text color="dark">
              Ved å akseptere denne avtalen får du tilgang til tjenesten, og du
              vil fra start av abonnement få en ikke-eksklusiv bruksrett til å
              benytte deg av Randi for egen virksomhet. Ved et aktivt abonnement
              skal Randi gi deg full tilgang til tjenesten, og samtidig bistå
              med teknisk support dersom det er behov for dette. Du er å anse
              som en lisenseier kun ved et aktivt abonnement, og du vil ha
              tilgang til tjenesten alle dager i året uten noen begrensninger.{" "}
              <br />
              <br />
              Det kan forekomme at Randi vil ha nedetid, selvom det er ytterst
              sjeldent. Dersom du kan dokumentere at din tilgang har blitt
              merkbart redusert som følge av vår nedetid vil det åpne seg
              muligheter for å diskutere en kompensasjon i form av lavere
              fremtidig lisenspris over en gitt tidsperiode for å gjøre opp for
              dette.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Opphavsrett</Title>
            <Text color="dark">
              Randi Labs AS eier Randi og har opphavsrett til denne tjenesten.
              Denne opphavsretten er beskyttet i lov om opphavsrett til
              åndsverk. Eiendomsretten til tjenesten tilhører Randi Labs AS,
              uavhengig av lisensiering av tjenesten til kunden i henhold til
              avtalen, som også gjelder både for originalversjon og eventuelle
              kopier. Ved bruk av denne tjenesten er du forpliktet til å
              behandle tjenesten som et hvert annet opphavsrettslig beskyttet
              materiale, og du skal ikke kopiere materiale fra Randi uten å
              oppgi kilder eller få et skriftlig samtykke fra Randi Labs AS.
              <br />
              <br />
              Som bruker har du heller ikke, under noen omstendigheter, lov til
              å selge eller overføre noe fra tjenesten over til en annen
              tredjepart eller virksomhet. Det er heller ikke under noen
              omstendigheter tillatt å dekompilere, reverse-engineere, bygge
              videre på eller dele opp noe av Randi-tjenesten, da dette er å
              anse som privat eiendom. All kontaktinformasjon som blir lagret
              hos Randi er å anse som våres eiendom, hvor data utenom er å anse
              som din (kunden) sin eiendom.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Lisens</Title>
            <Text color="dark">
              For tilgang til Randi skal det betales en månedlig lisensavgift
              til Randi Labs AS, som betales med betalingskort gjennom Stripe.
              Etter betaling vil du umiddelbart få tilgang til hele plattformen,
              og du vil fra datoen du starter abonnementet bli belastet èn gang
              hver måned for antall brukertilganger du har kjøpt. Etter kjøp har
              du ikke krav på refusjon, og dersom du ønsker å kansellere
              abonnementet ditt må dette gjøres før neste månedlige trekk.
              <br />
              <br />
              Ved kansellering vil abonnementet ditt automatisk bli stoppet, så
              lenge du ikke har noe utestående hos oss. I denne avtalen regnes
              èn bruker som en samarbeidspartner eller en person i et annet
              foretak med eksklusiv tilgang, og denne kan justeres både i
              registreringsprosessen og endres i etterkant.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Varighet og oppsigelse</Title>
            <Text color="dark">
              Brukere av Randi får tilgang til to gratis transkriberinger per
              måned, med en grense på 20 minutter per transkripsjon. Dersom du
              har behov for mer, kan du oppgradere til et betalt abonnement.
              <br />
              <br />
              Lisensen til Randi er gyldig én måned av gangen og betales
              forskuddsvis. Abonnementet fornyes automatisk dersom du har en
              aktiv betalingsplan og ikke har valgt å kansellere det. Du har
              ikke krav på refusjon etter gjennomført betaling, men du kan si
              opp abonnementet når som helst. Oppsigelsen vil gjelde fra
              nærmeste faktureringsperiode.
              <br />
              <br />
              Dersom du kun benytter deg av de inkluderte gratis
              transkriberingene, vil du ikke bli belastet med mindre du velger å
              oppgradere.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Ansvarsbegrensning</Title>
            <Text color="dark">
              Randi Labs AS fraskriver seg alt ansvar for ethvert tap, skade
              eller uhell som oppstår som følge av kundens bruk av tjenesten.
              Dette inkluderer, men er ikke begrenset til, feil i oversettelse,
              tekstoppbygning, formulering, transkribering eller andre feil som
              oppstår fra en tredjepart. Vi er heller ikke ansvarlige for
              forhold utenfor vår kontroll, som manglende teknisk kunnskap,
              begrensninger i teknisk utstyr eller lignende faktorer.
              <br />
              <br />
              Randi sin tale-til-tekst-tjeneste, som benytter OpenAI Whisper
              API, gir ingen garantier for nøyaktigheten av transkripsjonene.
              Feil og unøyaktigheter kan forekomme av flere årsaker, inkludert
              kvaliteten på lydopptaket, bakgrunnsstøy, dialekter, aksenter og
              kompleksiteten i det språklige innholdet. Det er brukerens ansvar
              å gjennomgå og verifisere transkripsjonene for nøyaktighet og
              fullstendighet.
              <br />
              <br />
              Brukeren gjøres oppmerksom på fenomenet "hallusinering", hvor
              modellen kan generere ord eller setninger som ikke opprinnelig ble
              sagt, men som kan fremkomme i transkripsjonen basert på modellens
              tolkning av konteksten. Randi Labs AS påtar seg intet ansvar for
              eventuelle feil eller misforståelser som oppstår som følge av
              dette.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Mislighold</Title>
            <Text color="dark">
              Dersom kunden motsetter seg eller av en annen grunn ikke betaler
              den månedlige lisensavgiften, har Randi rett til å hindre brukeren
              tilgang til tjenesten med umiddelbar virkning frem det utestående
              blir gjort opp, hvor i enkelte tilfeller renter og omkostninger
              vil foreligge. Ved ekstraordinære situasjoner som etter
              alminnelige regler må regnes som force majeure, anses ikke avtalen
              misligholdt så lenge force majeure situasjonen fortsatt er
              tilstede.
              <br />
              <br />
              Skulle situasjonen vedvare, eller hvis det er antydninger til at
              det vil pågå i mer enn 30 dager kan hver av partene si opp avtalen
              med 30 dagers forvarsel.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Overdragelse</Title>
            <Text color="dark">
              Kunden kan ikke under noen omstendigheter overføre avtalen til en
              annen fysisk eller juridisk person, ei heller en tredjepart uten
              skriftlig samtykke fra Randi Labs AS.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Generelt</Title>
            <Text color="dark">
              Denne avtalen erstatter alle eventuelle tidligere avtaler og
              forpliktelser, både muntlige og skriftlige, vedrørende tilgang til
              tjenesten både i regi av brukertilganger og pris. Denne avtalen
              reguleres av norsk lov, og tillegg eller endringer i avtalen er
              kun gyldig dersom ny, skriftlig avtale med henvisning til avtalen
              foreligger. Denne avtalen er bindende og skal ved eventuelle
              konflikter avgjøres etter norsk rett og vedtas ved Oslo Tingrett.
            </Text>
          </Paragraph>
        </Article>
      </Container>
    </Section>
  );
};

export default PrivacyPolicyContent;
