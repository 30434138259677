import { Link } from "react-router-dom";
import { Title } from "../../../library/title";
import {
  Article,
  Bold,
  Container,
  InfoBox,
  ListItem,
  OrderedList,
  Paragraph,
  Section,
  UnorderedList,
} from "./styled";
import { Text } from "../../../library/text";

const PrivacyPolicyContent = () => {
  return (
    <Section>
      <Container>
        <Article>
          <Paragraph>
            <Title>Personvern</Title>
            <Text color="dark">
              Randi Labs AS (“oss”, “vi” eller “vår”) driver nettstedet
              www.randi.ai (“tjenesten”). Vi er opptatt av at du skal ha tillit
              til oss og hvordan vi behandler dine personopplysninger. I denne
              personvernerklæringen forklarer vi derfor hvorfor vi samler inn
              informasjon om deg, hvordan vi bruker denne informasjonen og
              hvordan vi tar hensyn til ditt personvern.
              <br />
              <br />
              Personopplysninger er opplysninger og vurderinger som kan knyttes
              til en identifiserbar enkeltperson. Dette kan for eksempel være
              navn og kontaktinformasjon, kjøpshistorikk eller informasjon om
              interesser. <br />
              <br />
              Bruk av personopplysninger må følge personopplysningsloven. Vi,
              som behandlingsansvarlig, vil sørge for at vi bruker
              personopplysninger om deg i samsvar med lovens regler.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Hvilke personopplysninger har vi om deg</Title>
            <Text color="dark">
              Ved bruk av vår tjeneste ber vi deg ved registrering oppgi enkelte
              personopplysninger som kan brukes til å kontakte eller
              identifisere deg ("Personlig data"). Dette er følgende data vi
              lagrer fra deg:
            </Text>
            <OrderedList>
              <ListItem>
                <Bold>Opplysninger om deg:</Bold> Informasjon du oppgir når du
                oppretter en konto, som fornavn, etternavn, telefonnummer og
                e-postadresse. Du kan velge å gi oss mer informasjon om deg
                selv, som adresse, postnummer, poststed og land.
              </ListItem>
              <ListItem>
                <Bold>Opplastinger:</Bold> Vi lagrer all tekst, lyd og video du
                laster opp gjennom tjenesten, og dette er informasjon som kun
                vil være tilgjengelig for deg og de du ønsker å dele det med.
              </ListItem>
              <ListItem>
                <Bold>Betalingsinformasjon:</Bold>
                Vi benytter oss av Stripe som en tredjepartsløsning for å
                håndtere betalinger og vi lagrer derfor ingen kortinformasjon
                hos oss direkte. Du kan lese mer om hvordan Stripe håndterer
                personopplysninger ved å trykke{" "}
                <a href="https://stripe.com/no/privacy">her</a>. Du kan til
                enhver tid logge inn på vår kundeportal hos Stripe for å se og
                endre betalingsinformasjonen din, samt se hva slags informasjon
                som er lagret om deg.
              </ListItem>
              <ListItem>
                <Bold>Kommunikasjon:</Bold> Informasjon du gir oss når du har
                kontakt med vårt kundesenter, som e-poster sendt til og fra
                hverandre eller dialog som gjøres gjennom vår live-chat widget.
              </ListItem>
              <ListItem>
                <Bold>Opplysninger om bruk på nettsidene: </Bold>
                Data vi får når du bruker vår nettside og app, som hva slags
                enhet du benytter deg av (f.eks. mobil, nettbrett eller PC),
                siste aktivitet, IP-adresse og hvilke funksjonalitet du benytter
                deg av.
              </ListItem>
              <ListItem>
                <Bold>Profileringsopplysninger: </Bold>
                Informasjon og antakelser vi lager automatisk basert på de
                opplysningene (nevnt ovenfor) vi har om deg, som for eksempel
                interesser, preferanser og demografi, med mindre du ikke har
                gitt samtykke til dette.
              </ListItem>
            </OrderedList>
          </Paragraph>
          <Paragraph>
            <Title>Hva vi bruker personopplysningene dine til</Title>
            <Text color="dark">
              Vi bruker personopplysningene dine til følgende:
            </Text>
            <OrderedList>
              <ListItem>
                <Bold>Nettside og tjeneste:</Bold> Vi bruker dine opplysninger
                for å tilpasse innholdet i vår tjeneste og nettside basert på
                hva slags utstyr du benytter deg av (laptop, mobil eller
                nettbrett), og hva slags rolle, rettigheter og funksjonaliteter
                du skal ha tilgang til på plattformen.
              </ListItem>
              <ListItem>
                <Bold>Registrering:</Bold> Siden Randi.ai er en online tjeneste
                med mange brukere er det behov for å verifisere identiteten din,
                og vi bruker denne informasjonen for å gi deg spesifikk tilgang
                med e-postadresse og passord.
              </ListItem>
              <ListItem>
                <Bold>Kundeservice:</Bold> Vi lagrer meldingslogger fra e-poster
                som har blitt vekslet mellom oss, samt dialoger fra vår
                live-chat for å yte best mulig service og styrke kundeforholdet
                ytterligere.
              </ListItem>
              <ListItem>
                <Bold>Statistikk og monitorering:</Bold> I et forsøk på å hele
                tiden tilby deg den beste brukeropplevelsen benytter vi oss av
                tredjepartsløsninger for å holde oversikt over besøkende og se
                hvordan disse beveger seg på siden i et forsøk på å fange opp
                feil og gjøre forbedringer. Dette er data som ikke kan kobles
                direkte til deg som person, men bruker IP-adresse som referanse.
              </ListItem>
              <ListItem>
                <Bold>Markedsføring</Bold> Vi bruker din kontaktinformasjon til
                å sende deg anbefalinger og nyheter angående vår tjeneste, med
                mindre du har motsatt deg dette.
              </ListItem>
            </OrderedList>
          </Paragraph>
          <Paragraph>
            <Title>
              Rettslig grunnlag for behandling av personopplysninger under
              Personvernforordningen GDPR (General Data Protection Regulation)
            </Title>
            <Text color="dark">
              Hvis du befinner deg innenfor Det europeiske økonomiske
              samarbeidsområde (EØS), har vi rettslig grunnlag for å samle inn
              og bruke disse opplsyningene som er beskrevet i denne
              personvernerklæringen. Vi kan samle inn og behandle dine
              personlige data fordi:
            </Text>
            <OrderedList>
              <ListItem>Du må akseptere avtalen før registrering.</ListItem>
              <ListItem>Du samtykker til at vi kan samle inn dataen.</ListItem>
              <ListItem>Den overstyres ikke av dine rettigheter.</ListItem>
              <ListItem>For å overholde loven.</ListItem>
            </OrderedList>
          </Paragraph>
          <Paragraph>
            <Title>Hvem vi deler personopplysningene med</Title>
            <Text color="dark">
              Vi benytter oss av ulike tredjepartsleverandører for å kunne gi
              deg som kunde den beste brukeropplevelsen. Alle personopplysninger
              oppbevares på servere i EU gjennom Google Cloud Platform. For
              transkripsjon av lyd og video benytter vi OpenAI Whisper API, som
              behandles under vår databehandleravtale (DPA) med OpenAI Ireland.
              Dette sikrer at all behandling av personopplysninger skjer i
              henhold til GDPR, og at ingen data brukes til AI-trening eller
              deles med tredjeparter.
              <br />
              <br />
              Som bruker er du ansvarlig for det innholdet du velger å laste
              opp. Du er selv ansvarlig for å sikre at materialet du laster opp
              overholder gjeldende lovverk og regler, samt respekterer lisenser,
              rettighetene til tredjeparter og Norges lover.
              <br />
              <br />
              Randi Labs AS deler ikke dataen du laster opp med noen andre enn
              de du selv velger å dele det med. Dersom man ønsker å dele
              innholdet med noen andre, er det brukeren selv som må velge å
              sette en fil som <Bold>Delt</Bold>, hvor filen kun blir
              tilgjengelig for de som er i samme arbeidsmiljø dersom man har
              flere lisenser. Man står selv fritt til å dele det bearbeidede
              materialet (eks. lyd, video og tekst) med hvem man måtte ønske når
              man har lastet det ned.
              <br />
              <br />
            </Text>
            <OrderedList>
              <ListItem>
                <Bold>Google Cloud Platform:</Bold> Google Cloud tilbyr en rekke
                tjenester som analyse, infrastruktur og databehandling, og vi
                benytter dette for å sørge for en sikker, skalerbar og stabil
                drift av tjenesten. All data som lagres gjennom Google Cloud er
                plassert på servere innenfor EU.
              </ListItem>
              <ListItem>
                <Bold>Facebook:</Bold> Vi deler informasjon med Facebook for å
                drive målrettet og effektiv markedsføring av plattformen vår.
                Dette gjør vi for å sørge for at vi hele tiden kan nå ut til en
                målgruppe som er interessert i tjenesten vår basert på
                informasjon Facebook har om de respektive brukerne de viser
                annonser til.
              </ListItem>
              <ListItem>
                <Bold>Hotjar:</Bold> Dette er et verktøy for å monitorere
                aktivitet på nettsiden vår og hjelper oss med å forstå
                brukeratferd. På denne måten kan vi enklere analysere
                funksjonalitet og forbedre løsningen kontinuerlig. Data som
                samles inn via Hotjar er anonymisert og kan ikke spores tilbake
                til individuelle brukere.
              </ListItem>
              <ListItem>
                <Bold>Stripe: </Bold>
                Vi benytter Stripe som en tredjepartsløsning for å håndtere
                betalinger gjennom tjenesten vår. Stripe er en PCI
                DSS-sertifisert betalingsløsning og en av de mest anerkjente og
                sikre betalingsløsningene på markedet. Stripe lagrer all
                betalingsinformasjon på sine servere, og vi lagrer derfor ingen
                kortinformasjon hos oss direkte. Gjennom vår kundeportal hos
                Stripe kan du til enhver tid se og administrere abonnementet
                ditt og betalingsinformasjonen du har oppgitt.
              </ListItem>
              <ListItem>
                <Bold>OpenAI: </Bold>
                Randi benytter OpenAI Whisper API for
                tale-til-tekst-transkripsjon. Vi har inngått en
                databehandleravtale (DPA) med OpenAI Ireland, som sikrer at all
                behandling av personopplysninger skjer i henhold til
                GDPR-regelverket.
                <br />
                <br />
                <ul>
                  <li>
                    OpenAI Ireland er vår registrerte databehandler i EU, og all
                    behandling skjer under europeisk personvernlovgivning.
                  </li>
                  <li>
                    Ingen data brukes til AI-trening: OpenAI benytter ikke våre
                    data til modelltrening eller analyse.
                  </li>
                  <li>
                    Automatisk sletting: Data som behandles av Whisper API blir
                    midlertidig lagret i opptil 30 dager før det slettes
                    permanent.
                  </li>
                  <li>
                    Kryptert og sikret: All data som behandles av Randi er
                    kryptert i hvile og under overføring. OpenAI Whisper API,
                    Google Cloud Platform og Stripe bruker TLS 1.2+ for sikre
                    dataoverføringer og AES-256-kryptering for lagring. OpenAI
                    har også SOC 2 Type II-sertifisering.
                  </li>
                </ul>
              </ListItem>
            </OrderedList>
          </Paragraph>
          <Paragraph>
            <Title>Dataeierskap</Title>
            <Text color="dark">
              All data du laster opp, endrer på og laster ned gjennom Randi
              forblir ditt eget og Randi Labs AS tar ingen eierskap til dette
              materialet. Kunden har selv fullt eierskap til alt innhold som
              produseres gjennom tjenesten til evig tid, og Randi Labs AS har
              ingen rettigheter til å bruke dette innholdet uten spesielt
              skriftlig samtykke.
              <br />
              <br />
              Til tross for at materialet du laster opp går gjennom OpenAI sine
              servere, så vil ikke eierskapet på noen måte overføres til noen
              andre enn den rettmessige eieren (kunden).
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Hvor lenge lagrer vi personopplysninger</Title>
            <Text color="dark">
              Vi lagrer personopplysninger om deg så lenge det er nødvendig for
              å oppnå målet det i utgangspunktet ble innhentet for, eller dersom
              vi er pålagt å lagre opplysningene. All data og informasjon vil
              slettes eller anonymiseres når det ikke lenger er nødvendig å ha
              dette lagret. Nedenfor finner du en oversikt over hvor lenge vi
              lagrer dine opplysninger:
            </Text>
            <br />
            <br />
            <OrderedList>
              <ListItem>
                <Bold>Opplysninger om deg:</Bold> Hvis du har registrert en
                bruker hos oss, slettes all data koblet til kontoen automatisk
                etter 12 måneder med inaktivitet. Du vil få en varsle på e-post
                som melder fra om dette på forhånd.
              </ListItem>
              <ListItem>
                <Bold>Kjøpsinformasjon:</Bold> Vi lagrer aldri noe
                kjøpsinformasjon direkte hos oss, men kun detaljer vi får fra
                kjøpet gjennom Stripe. Dette inkluderer customer id, session id,
                subscription id og dato for kjøpet. Med andre ord ingen
                kortinformasjon overhode, og denne informasjonen vil forsvinne
                samtidig som annen info etter inaktivitet over 12 måneder.
              </ListItem>
              <ListItem>
                <Bold>Bruk av tjenesten:</Bold> Vi lagrer informasjon om din
                bruk av våre tjenester opp til 6 måneder.
              </ListItem>
              <ListItem>
                <Bold>Partnere/tredjepart:</Bold> Vi lagrer informasjon fra
                samarbeidspartnere så lenge du har samtykket til dette, eller
                har en aktiv konto registert hos oss. Disse opplysningene vil
                slettes ved inaktivitet etter 12 måneder.
              </ListItem>
              <ListItem>
                <Bold>Kommunikasjon: </Bold> Vi lagrer kommunikasjon med våre
                brukere og kunder i tre år, med mindre vi har behov for å lagre
                det i en lenger periode ved f.eks en klage eller et krav.
              </ListItem>
              <ListItem>
                <Bold>Profileringsopplysninger: </Bold> Dersom du har registert
                en bruker hos oss, slettes disse opplysningene når du sletter
                kontoen eller hvis den har vært inaktiv i 12 måneder. Dersom du
                ønsker å slette denne informasjonen tidligere, så gjør vi det
                dersom det er ønskelig.
              </ListItem>
            </OrderedList>
          </Paragraph>
          <Paragraph>
            <Title>Dine rettigheter</Title>
            <Text color="dark">Du har rett til å be oss om følgende:</Text>
            <br />
            <br />
            <UnorderedList>
              <ListItem>
                Sende deg en kopi med alle personopplysninger vi har lagret om
                deg.
              </ListItem>
              <ListItem>
                Gi deg mer informasjon om hvordan vi behandler akkurat dine
                personopplysninger.
              </ListItem>
              <ListItem>Oppdatere personopplysningene dine.</ListItem>
              <ListItem>
                Slette opplysninger om deg som vi ikke lenger har grunnlag til å
                oppbevare.
              </ListItem>
              <ListItem>
                Stoppe eller begrense behandlingen av opplysninger om deg.
              </ListItem>
              <ListItem>
                Motsette deg automatiske individuelle avgjørelser, inkludert
                profilering.
              </ListItem>
              <ListItem>
                Sende deg opplysninger du har gitt til oss i et strukturert og
                lesbart format.
              </ListItem>
              <ListItem>
                Angre på eventuelle samtykker du har gitt oss vedrørende lagring
                av dine opplysninger.
              </ListItem>
            </UnorderedList>
            <Text color="dark">
              Det finnes begrensninger i disse rettighetene. Ta kontakt med oss
              hvis du vil ha mer informasjon vedrørende dette.
              <br />
              <br />
              Dersom du mener at vi behandler eller lagrer dine
              personopplysninger i strid med personopplysningsloven eller GDPR
              har du rett til å klage inn dette til Datatilsynet. Før du
              eventuelt gjør dette, er det ønskelig at du når ut til oss slik at
              vi kan oppklare eventuelle misforståelser eller svare på dine
              spørsmål.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Cookies</Title>
            <Text color="dark">
              Vi bruker cookies på våre sider for å sørge for at du får den
              aller beste brukeropplevelsen ved å sikre funksjonalitet,
              analysere trafikk og gjennomgå brukeratferd. Du kan lese mer om
              cookies og hvordan vi anvender dem ved å trykke{" "}
              <Link to="/cookies">her</Link>.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Nå ut til oss</Title>
            <Text color="dark">
              Hvis du har noen spørsmål angående personvernerklæringen kan du
              kontakte oss på mail ved å trykke{" "}
              <a href="mailto:andreas@randi.ai">her</a>.
            </Text>
          </Paragraph>
        </Article>
      </Container>
    </Section>
  );
};

export default PrivacyPolicyContent;
